"use strict";

$(".menu-btn").click(function () {
    if ($(".main-menu, .sub-menu").hasClass("active")) {
        $(".main-menu, .sub-menu").removeClass("active");
    } else {
        $(".main-menu, .sub-menu").addClass("active");
    }
});

$(".close-btn").click(function () {
    $(".main-menu, .sub-menu").removeClass("active");
});